// TODO mlockard, this file is declared as side effectful but doing so is preventing most of our tree shaking
/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { ApolloProvider } from "@apollo/client";
import * as FS from "@fullstory/browser";
import {
  AGENT_TYPE_HEADER,
  AGENT_VERSION_HEADER,
  AgentType,
  AppFeatureFlag,
  AuthenticatedUserOrgDetails,
  DEFAULT_FLAGS,
  OrgRole,
  TRACE_ID_HEADER,
  UnAuthenticatedUserOrgDetails,
  UserOrgDetails,
  createTraceId,
  getProductionEnvironment,
} from "@hex/common";
import { createBrowserHistory } from "history";
import { LDClient } from "launchdarkly-js-client-sdk";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import { endsWith } from "lodash";
import MouseTrap from "mousetrap";
import React, { ReactNode, Suspense, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { Provider as ReduxProvider } from "react-redux";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";

import { AtomicControllerContext } from "./atomic-operations/AtomicControllerContext.js";
import { client, connectionManager } from "./client";
import { ElementMeasurer } from "./components/common/ElementMeasurer";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { Favicon } from "./components/common/Favicon";
import { ToasterProvider } from "./components/common/Toasts";
import { Org } from "./generated/graphqlTypes";
import { LD_CONFIG } from "./global-constants";
import { useCurrentUser } from "./hooks/me/useCurrentUser";
import { useAttribution } from "./hooks/useAttribution";
import { ORG_ID, getUrlOrg } from "./orgs";
import {
  OrgsClientDocument,
  OrgsClientQuery,
  OrgsPickerClientDocument,
  OrgsPickerClientQuery,
} from "./orgs.generated";
import { createStore } from "./redux/store";
import {
  InvalidLinkRoute,
  LoggedInLinkRoute,
  WrongUserLinkRoute,
} from "./route/LinkRoutes";
import { LoginRoute } from "./route/LoginRoute";
import { MagicLinkSentRoute } from "./route/MagicLinkSentRoute";
import NewProjectRouteRedirect from "./route/new-project-routes/NewProjectRouteRedirect";
import { Routes } from "./route/routes";
import { SignupRoute } from "./route/SignupRoute";
import { WorkspacePickerRoute } from "./route/WorkspacePickerRoute";
import { GlobalStyle } from "./theme/common/GlobalStyle";
import ThemeWrapper from "./ThemeWrapper";
import { usePerformanceStats } from "./usePerformanceStats";
import { ConnectionManagerProvider } from "./util/ConnectionManager";
import {
  isSingleTenant,
  launchDarklyDisabled,
  webClientVersion,
} from "./util/data";
import { shouldIgnoreShortcut } from "./util/Keys";
import { reactLazyRetry } from "./util/reactLazyRetry";
import { RUDDER_ANALYTICS } from "./util/rudderstack.js";
import { TRACE_LOG } from "./util/TraceLog";
import { sendEvents } from "./util/trackEvent.js";
import { useGetFavicon } from "./util/useGetFavicon.js";
import { BigQueryOAuthSuccessView } from "./views/BigQueryOAuthSuccessView.js";
import { DatabricksOAuthSuccessView } from "./views/DatabricksOAuthSuccessView.js";
import { GoogleDriveOauthCompleteView } from "./views/GoogleDriveOauthCompleteView";
import { LoadingView } from "./views/LoadingView";
import { LoginSuccessView } from "./views/LoginSuccessView";
import { NotionUnfurlAuthorizeView } from "./views/NotionUnfurlAuthorizeView";
import { SnowflakeOauthSuccessView } from "./views/SnowflakeOauthSuccessView";

const urlOrg = getUrlOrg();
export const appHistory = createBrowserHistory({
  basename: urlOrg != null ? `/${urlOrg}` : undefined,
});

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
`;

MouseTrap.prototype.stopCallback = shouldIgnoreShortcut;

client.writeQuery<OrgsClientQuery>({
  query: OrgsClientDocument,
  data: {
    __typename: "Query",
    orgsClient: {
      __typename: "ClientOrgResponse",
      orgs: [],
      loaded: false,
    },
  },
});

const orgInitTraceId = createTraceId();

TRACE_LOG.push({
  traceId: orgInitTraceId,
  status: "STARTING",
  name: "org init",
});

void fetch("/orgs", {
  headers: {
    [TRACE_ID_HEADER]: orgInitTraceId,
    [AGENT_TYPE_HEADER]: AgentType.WEB_CLIENT,
    [AGENT_VERSION_HEADER]: webClientVersion ?? "<unknown>",
  },
})
  .then(async (res) => {
    TRACE_LOG.push({ traceId: orgInitTraceId, status: "OK", name: "org init" });
    const body: Omit<Org, "__typename">[] = await res.json();

    client.writeQuery<OrgsClientQuery>({
      query: OrgsClientDocument,
      data: {
        __typename: "Query",
        orgsClient: {
          __typename: "ClientOrgResponse",
          orgs: body.map((org) => ({ ...org, __typename: "Org" })),
          loaded: true,
        },
      },
    });
  })
  .catch((err) => {
    TRACE_LOG.push({
      traceId: orgInitTraceId,
      status: "ERROR",
      name: "org init",
    });
    throw err;
  });

const orgPickerInitTraceId = createTraceId();

TRACE_LOG.push({
  traceId: orgPickerInitTraceId,
  status: "STARTING",
  name: "org picker init",
});

void fetch("/workspace-picker-orgs", {
  headers: {
    [TRACE_ID_HEADER]: orgPickerInitTraceId,
    [AGENT_TYPE_HEADER]: AgentType.WEB_CLIENT,
    [AGENT_VERSION_HEADER]: webClientVersion ?? "<unknown>",
  },
})
  .then(async (res) => {
    TRACE_LOG.push({
      traceId: orgPickerInitTraceId,
      status: "OK",
      name: "org picker init",
    });
    const body = await res.json();

    // Ensure all Authed orgs match expected type
    const authenticatedUsers = body.authedOrgDetails
      .filter((data: UserOrgDetails) => AuthenticatedUserOrgDetails.guard(data))
      .map((data: AuthenticatedUserOrgDetails) => {
        return {
          id: data.userId,
          orgRole: data.orgRole,
          email: data.email,
          org: {
            __typename: "Org",
            id: data.id,
            displayName: data.displayName,
            // when querying for orgs we won't know when the user is requesting
            // for the SUPPORT_HEX_EMAIL so if usercount is undefined we just
            // set it to -1
            userCount: data.userCount ?? -1,
          },
          __typename: "User",
        };
      });

    // Ensure all unauthed orgs match expected type
    const unauthenticatedUsers = body.unAuthedOrgDetails
      .filter(
        (data: UserOrgDetails) =>
          UnAuthenticatedUserOrgDetails.guard(data) &&
          !AuthenticatedUserOrgDetails.guard(data),
      )
      .map((data: UserOrgDetails) => {
        return {
          __typename: "User",
          id: data.id,
          email: data.email,
          org: {
            __typename: "Org",
            id: data.id,
            displayName: data.displayName,
          },
        };
      });

    client.writeQuery<OrgsPickerClientQuery>({
      query: OrgsPickerClientDocument,
      data: {
        __typename: "Query",
        orgPickerClient: {
          __typename: "ClientOrgPickerResponse",
          loaded: true,
          authenticatedUsers,
          unauthenticatedUsers,
        },
      },
    });
  })
  .catch((err) => {
    TRACE_LOG.push({
      traceId: orgPickerInitTraceId,
      status: "ERROR",
      name: "org picker init",
    });
    throw err;
  });

const LazyOrgSelectorRoute = reactLazyRetry(
  () => import("./route/OrgSelectorRoute"),
);

const LazyHexRoutes = reactLazyRetry(
  () => import(/* webpackPrefetch: true */ "./route/HexRoutes"),
);

const LazySpellbookRoute = reactLazyRetry(
  () => import("./route/SpellbookRoute"),
);

const LazyGitSetupRoute = reactLazyRetry(() => import("./route/GitSetupRoute"));

const PageViewWrapper: React.FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  // Tracks page view events via Rudderstack
  // Must be inserted between Router and Routes to track page changes
  const routerLocation = useLocation();

  useEffect(() => {
    RUDDER_ANALYTICS.page();
  }, [routerLocation]);

  return <>{children}</>;
};

const LoginRedirect: React.FunctionComponent = () => {
  const attribution = useAttribution();
  return <Redirect to={Routes.LOGIN.getUrl({ attribution })} />;
};

const FULL_STORY_ORG_ID = "o-1AX0GZ-na1";

const INTERNAL_ORG_IDS = ["hex", "hex-recruiting"];
const INTERNAL_EMAIL_DOMAIN = "@hex.tech";

const IGNORED_INTERNAL_ORG_IDS = [
  "hex-testing",
  "legends",
  "atreides",
  "hex-public",
];
const FullStoryWrapper: React.FunctionComponent<{
  children: ReactNode;
  environment: string;
}> = ({ children, environment }) => {
  const currentUser = useCurrentUser();
  const initialized = FS.isInitialized();
  useEffect(() => {
    if (
      currentUser &&
      endsWith(currentUser.email, INTERNAL_EMAIL_DOMAIN) &&
      !INTERNAL_ORG_IDS.includes(ORG_ID)
    ) {
      return;
    }

    if (IGNORED_INTERNAL_ORG_IDS.includes(ORG_ID)) {
      return;
    }

    // Block automated testing domains from being captured by FS
    if (currentUser && currentUser.email.includes("mailosaur.net")) {
      return;
    }

    // IMPORTANT: MULTI-TENANT ONLY!!!
    if (environment === "prod" && !isSingleTenant) {
      if (!initialized) {
        FS.init({ orgId: FULL_STORY_ORG_ID, namespace: "FullStory" });
      }
      if (currentUser && currentUser.orgRole !== OrgRole.ANONYMOUS) {
        FS.identify(currentUser.id, {
          email: currentUser.email,
          orgRole: currentUser.orgRole,
          platformRole: currentUser.platformRole,
          orgId: ORG_ID,
        });
      }
    }
  }, [currentUser, environment, initialized]);

  return <>{children}</>;
};

const App: React.FunctionComponent = () => {
  const ldClientRef = useRef<LDClient | null>(null);
  const ldClient = useLDClient();

  if (ldClient != null) {
    ldClientRef.current = ldClient;
  }

  const storeRef = useRef<ReturnType<typeof createStore>>();
  if (storeRef.current == null) {
    storeRef.current = createStore(ORG_ID, {
      getHexFlag: (flag: AppFeatureFlag) =>
        ldClientRef.current?.variation(flag) ?? DEFAULT_FLAGS[flag],
    });
  }

  const environment = getProductionEnvironment(window.location.hostname);

  const favicon = useGetFavicon({ inProject: false });

  usePerformanceStats();

  useEffect(() => {
    // ensure that we send all track event data when users closes out of session.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        sendEvents();
      }
    });
  }, []);

  return (
    <ReduxProvider store={storeRef.current}>
      <Router history={appHistory}>
        <ErrorBoundary reloadPageOnTryAgain={true}>
          <AtomicControllerContext>
            <ApolloProvider client={client}>
              <ConnectionManagerProvider value={connectionManager}>
                <FullStoryWrapper environment={environment}>
                  <ThemeWrapper>
                    <AppContainer>
                      <Helmet>
                        <title>Hex</title>
                      </Helmet>
                      <Favicon href={favicon} />
                      <GlobalStyle />
                      <ErrorBoundary reloadPageOnTryAgain={true}>
                        <Suspense fallback={<LoadingView />}>
                          <ToasterProvider>
                            <ElementMeasurer />
                            <DndProvider backend={HTML5Backend}>
                              <PageViewWrapper>
                                {urlOrg == null ? (
                                  <Switch>
                                    <Route path={Routes.NEW_PROJECT.path}>
                                      <NewProjectRouteRedirect />
                                    </Route>
                                    <Route
                                      path={[
                                        `${Routes.SIGNUP.path}${Routes.MAGIC_LINK_SENT.path}`,
                                        `${Routes.LOGIN.path}${Routes.MAGIC_LINK_SENT.path}`,
                                      ]}
                                    >
                                      <MagicLinkSentRoute />
                                    </Route>
                                    <Route path={Routes.WORKSPACE_PICKER.path}>
                                      <WorkspacePickerRoute />
                                    </Route>
                                    <Route path={Routes.LINK_WRONG_USER.path}>
                                      <WrongUserLinkRoute />
                                    </Route>
                                    <Route path={Routes.LINK_LOGGED_IN.path}>
                                      <LoggedInLinkRoute />
                                    </Route>
                                    <Route path={Routes.LINK_INVALID.path}>
                                      <InvalidLinkRoute />
                                    </Route>
                                    <Route path={Routes.SIGNUP.path}>
                                      <SignupRoute />
                                    </Route>
                                    <Route path={Routes.SPELLBOOK.path}>
                                      <LazySpellbookRoute />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={[
                                        Routes.GITHUB_PACKAGE_CONNECT_SUCCESS
                                          .path,
                                        Routes.GITHUB_PACKAGE_INSTALL_LANDING
                                          .path,
                                        Routes
                                          .GITHUB_SERVER_PACKAGE_CONNECT_SUCCESS
                                          .path,
                                        Routes
                                          .GITHUB_SERVER_PACKAGE_INSTALL_LANDING
                                          .path,
                                        Routes.GITLAB_PACKAGE_INSTALL_LANDING
                                          .path,
                                        Routes.GITHUB_SYNC_INSTALL_LANDING.path,
                                        Routes.GITHUB_SYNC_CONNECT_SUCCESS.path,
                                        Routes
                                          .GITHUB_SERVER_SYNC_INSTALL_LANDING
                                          .path,
                                        Routes
                                          .GITHUB_SERVER_SYNC_CONNECT_SUCCESS
                                          .path,
                                        Routes.GITLAB_SYNC_INSTALL_LANDING.path,
                                      ]}
                                    >
                                      <LazyGitSetupRoute />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={Routes.NOTION_UNFURL_AUTHORIZE.path}
                                    >
                                      <NotionUnfurlAuthorizeView />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={Routes.BIGQUERY_OAUTH_SUCCESS.path}
                                    >
                                      <BigQueryOAuthSuccessView />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={Routes.SNOWFLAKE_OAUTH_SUCCESS.path}
                                    >
                                      <SnowflakeOauthSuccessView />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={
                                        Routes.DATABRICKS_OAUTH_SUCCESS.path
                                      }
                                    >
                                      <DatabricksOAuthSuccessView />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={
                                        Routes.GOOGLE_DRIVE_OAUTH_COMPLETE.path
                                      }
                                    >
                                      <GoogleDriveOauthCompleteView />
                                    </Route>
                                    <Route path={Routes.ORG_SELECTOR.path}>
                                      <LazyOrgSelectorRoute />
                                    </Route>
                                    <Route path={Routes.LOGIN.path}>
                                      <LoginRoute />
                                    </Route>
                                    <Route
                                      exact={true}
                                      path={Routes.LOGIN_SUCCESS.path}
                                    >
                                      <LoginSuccessView />
                                    </Route>
                                    <Route path="/">
                                      <LoginRedirect />
                                    </Route>
                                  </Switch>
                                ) : (
                                  <LazyHexRoutes />
                                )}
                              </PageViewWrapper>
                            </DndProvider>
                          </ToasterProvider>
                        </Suspense>
                      </ErrorBoundary>
                    </AppContainer>
                  </ThemeWrapper>
                </FullStoryWrapper>
              </ConnectionManagerProvider>
            </ApolloProvider>
          </AtomicControllerContext>
        </ErrorBoundary>
      </Router>
    </ReduxProvider>
  );
};

export default launchDarklyDisabled ? App : withLDProvider(LD_CONFIG)(App);
